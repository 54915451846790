import React from 'react';

export function EntityLabel(props: { displayName: string, rla?: string, abnAcn?: string, profileName?: string, tradingName?: string, companyName?: string }) {
  const { headline, subtitle } = getCompanyNamingLines(props.companyName, props.tradingName, props.profileName);
  return <div className='d-flex flex-column'>
    <strong>{headline}</strong>
    {subtitle && <div className={'text-muted'}><small>{subtitle}</small></div>}
    <div className='d-flex flex-row gap-3 text-muted'>
      <small>Agent No: {props.rla || '-'}</small><small>ABN/ACN: {props.abnAcn || '-'}</small>
    </div>
  </div>;
}

export function getCompanyNamingLines(company?: string, trading?: string, profile?: string) {
  return getCompanyNamingLinesInner(company?.trim() || '', trading?.trim() || '', profile?.trim() || '');
}

function getCompanyNamingLinesInner(company: string, trading: string, profile: string) {
  if (trading === company) {
    company = '';
  }

  if (profile) {
    return { headline: profile, subtitle: getTA(company, trading) };
  }

  if (trading) {
    return { headline: trading, subtitle: company };
  }

  return { headline: company, subtitle: '' };
}

function getTA(company: string, trading: string) {
  return [
    company,
    trading
  ].filter(x => x && x.trim()).join(' T/A ');
}
