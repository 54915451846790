import React, { ReactNode } from 'react';
import 'allotment/dist/style.css';
import { BreadCrumbs } from './BreadCrumbs';
import clsJn from '@property-folders/common/util/classNameJoin';

import './Wizard/Wizard.scss';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useMediaQuery } from 'react-responsive';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { Property } from 'csstype';

export const ContentTitler = ({
  title,
  titleBadge,
  children,
  breadcrumbs,
  className,
  afterBreadcrumbs,
  breadcrumbsClassName,
  afterTitle,
  flex,
  flexDirection = 'column',
  scroll,
  narrowWholePageScroll = false
}: {
  title: string,
  titleBadge?: JSX.Element,
  children: ReactNode
  breadcrumbs?: CrumbDefn[],
  className?: string,
  afterBreadcrumbs?: ReactNode,
  breadcrumbsClassName?: string,
  afterTitle?: ReactNode,
  flex?: boolean,
  flexDirection?: Property.FlexDirection,
  scroll?: boolean,
  narrowWholePageScroll?: boolean
}): JSX.Element => {
  const narrowMode = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const usingNarrowWholePageScroll = narrowWholePageScroll && narrowMode;

  return <div className={clsJn('WizardPanel', className)}>
    <div className={clsJn('Container container-fluid h-100 g-1 p-0', !(usingNarrowWholePageScroll) && scroll && 'overflow-hidden', flex && 'd-flex flex-column' )}>
      <div className={clsJn({ 'w-100': true, 'flex-grow-0': flex })}>
        <div className='d-flex align-items-center px-3 py-2 gap-2 flex-wrap flex-row'>
          <div
            className='display-6 align-content-center me-auto overflow-hidden text-nowrap text-overflow-ellipsis'
            style={{ lineHeight: 1.3 }}
          >
            <div className={'d-flex flex-row'}>{title}{titleBadge && <div className={'d-flex align-items-end ms-3 mb-2'}>{titleBadge}</div>}</div>
          </div>
          {afterTitle}
        </div>
        {breadcrumbs && <div className='d-flex flex-row align-items-center justify-content-between w-100 mb-3 px-3'>
          <div><BreadCrumbs className={breadcrumbsClassName} segments={breadcrumbs}/></div>
          {afterBreadcrumbs && <div>{afterBreadcrumbs}</div>}
        </div>}
      </div>
      <hr style={{ margin: 0, marginBottom: 0 }}/>
      <div id='form-scroll-content' className={clsJn({
        'overflow-hidden': !scroll,
        'overflow-auto': scroll,
        'position-relative': true,
        'h-100': !flex,
        'flex-grow-1': flex,
        'w-100': true,
        'd-flex': flex
      })} style={{
        flexDirection: flex
          ? flexDirection || 'column'
          : undefined
      }}>
        {children}
      </div>
    </div>
  </div>;
};
