import { useYDeniedIds } from './YManagerContext';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Icon } from '../dragged-components/Icon';
import { Button, Spinner } from 'react-bootstrap';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';

export function YAccessRequiredCheck(props: PropsWithChildren<{ deniedElement?: (id: string) => ReactNode }>) {
  const { transId } = useLoaderData() as {transId: string} || {};
  const ids = useYDeniedIds();
  console.log('YAccessRequiredCheck', transId, ids);
  if (transId && ids.has(transId)) {
    return props.deniedElement
      ? <>{props.deniedElement(transId)}</>
      : <DefaultAccessDenied />;
  }
  return <>{props.children}</>;
}

export function DefaultAccessDenied() {
  const navigate = useNavigate();
  return <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center'>
    <Icon name='lock' icoClass='fs-1 text-muted' />
    <h1>Access denied</h1>
    <p>You don't have permission to access this content.</p>
    <p>Contact an Administrator to get permissions or go to the home page and browse other content.</p>
    <div><Button onClick={() => navigate('/')}>Home</Button></div>
  </div>;
}

export function PropertyAccessDenied({ propertyId }: { propertyId: string }) {
  const navigate = useNavigate();
  const [owner, setOwner] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    WrappedFetch.json<{ name: string }>(LinkBuilder.restApi(`/properties/${propertyId}/owner`))
      .then(data => setOwner(data.name || ''))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  return <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center'>
    <Icon name='lock' icoClass='fs-1 text-muted' />
    <h1>Access denied</h1>
    <p>You don't have permission to access this content.</p>
    {loading
      ? <Spinner animation='border' />
      : owner
        ? <p>{owner} is the owner of this content and may be able to grant you access to it.</p>
        : <p>If you believe you should have access to this content, contact an Administrator.</p>}
    <div><Button onClick={() => navigate('/properties')}>Properties</Button></div>
  </div>;
}
