import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import './HostedSigning.scss';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import {
  SignerProxyAuthorityRoleOptions,
  SigningParty,
  SigningPartyDeclineType,
  SigningSession
} from '@property-folders/contract';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { SigningProcessShim } from './SigningProcess';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { useEntity } from '@property-folders/components/hooks/useEntity';
import { companyTradingWithFallback } from '@property-folders/common/util/formatting';
import { MarkdownRenderer } from '../../display/MarkdownRenderer';
import { Predicate } from '@property-folders/common/predicate';
import { Avatar } from '../Avatar';

/**
 * Note: order of these values matters
 */
enum HostedSigningInstructionState {
  SalespersonProcessOverview,
  SalespersonVerifyIdentityOfSigningParty,
  SalespersonPassControlToSigningParty,
  SigningPartySigning,
  SigningPartyPassControlToSalesperson,
  NotifySalespersonOfPartyDecline
}

function SalespersonProcessOverview({ next, cancel, formPath, partyId, documentName }: { next: () => void, cancel: () => void, formPath: string, partyId: string, documentName: string }) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const {
    value: session
  } = useLightweightTransaction<SigningSession>({
    parentPath: formPath,
    myPath: 'signing.session',
    bindToMetaKey: true
  });
  const {
    value: signingParty
  } = useLightweightTransaction<SigningParty>({
    parentPath: formPath,
    myPath: `signing.parties.[${partyId}]`,
    bindToMetaKey: true
  });

  if (!(session?.initiator && signingParty && sessionInfo)) {
    return <></>;
  }

  const initiatorName = session.initiator.name;
  const localEntity = useEntity(session.initiator.entity.id);
  const entityName = companyTradingWithFallback(localEntity?.name, localEntity?.tradeName);
  const proxyMode = Predicate.proxyNotSelf(signingParty.proxyAuthority);
  const partyName = (proxyMode ? signingParty.proxyName : signingParty.snapshot?.name) || 'Unknown';

  return <Modal.Dialog className={'bg-white mt-5 p-3'} style={{ maxWidth: '600px' }}>
    <Modal.Header className={'mb-3'}>
      <Avatar name={initiatorName} entityName={entityName} agentId={session.initiator.id} />
    </Modal.Header>
    <Modal.Body>
      <p>You are hosting <b>{partyName}</b> as they sign the <b>{documentName}</b>{proxyMode && <> on behalf of <b>{signingParty.snapshot?.name}</b> in their role as <b>{SignerProxyAuthorityRoleOptions[signingParty.proxyAuthority]}</b></>}.</p>
      <p>As a host, you will:</p>
      <ol>
        <li className={'pb-1'}>Confirm the identity of {partyName}.</li>
        <li className={'pb-1'}>Pass control of this device to {partyName}.</li>
        <li className={'pb-1'}>Confirm distribution of the fully signed document.</li>
        <li>Regain control of this device.</li>
      </ol>
    </Modal.Body>
    <Modal.Footer>
      <div className={'d-flex flex-column'}>
        {/*<div>Check here to skip this message in the future.</div>*/}
        <div className={'d-flex flex-row gap-3 justify-content-end'}>
          <Button variant={'outline-secondary'} onClick={() => cancel()}>Cancel</Button>
          <Button variant={'light'} onClick={() => next()}>Start</Button>
        </div>
      </div>
    </Modal.Footer>
  </Modal.Dialog>;
}

function SalespersonVerifyIdentityOfSigningParty({ next, cancel, formPath, partyId }: { next: () => void, cancel: () => void, formPath: string, partyId: string }) {
  const {
    value: session
  } = useLightweightTransaction<SigningSession>({
    parentPath: formPath,
    myPath: 'signing.session',
    bindToMetaKey: true
  });
  const {
    value: signingParty
  } = useLightweightTransaction<SigningParty>({
    parentPath: formPath,
    myPath: `signing.parties.[${partyId}]`,
    bindToMetaKey: true
  });
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  if (!(session?.initiator && signingParty && sessionInfo)) {
    return <></>;
  }

  const initiatorName = session.initiator.name;
  const localEntity = useEntity(session.initiator.entity.id);
  const entityName = companyTradingWithFallback(localEntity?.name, localEntity?.tradeName);
  const proxyMode = Predicate.proxyNotSelf(signingParty.proxyAuthority);
  const partyName = (proxyMode ? signingParty.proxyName : signingParty.snapshot?.name) || 'Unknown';

  return <Modal.Dialog className={'bg-white mt-5 p-3'} style={{ maxWidth: '600px' }}>
    <Modal.Header className={'mb-3'}>
      <Avatar name={initiatorName} entityName={entityName} agentId={session.initiator.id} />
    </Modal.Header>
    <Modal.Body>
      <p>{sessionInfo.name}, you are witnessing {partyName} complete the electronic signing process.</p>
      <p>By clicking continue, you are confirming that you are satisfied with {partyName}'s identity.</p>
    </Modal.Body>
    <Modal.Footer>
      <div className={'d-flex flex-column'}>
        {/*<div>Check here to skip this message in the future.</div>*/}
        <div className={'d-flex flex-row gap-3 justify-content-end'}>
          <Button variant={'outline-secondary'} onClick={() => cancel()}>Cancel</Button>
          <Button variant={'light'} onClick={() => next()}>Continue</Button>
        </div>
      </div>
    </Modal.Footer>
  </Modal.Dialog>;
}

function SalespersonPassControlToSigningParty({ next, cancel, formPath, partyId }: { next: () => void, cancel: () => void, formPath: string, partyId: string }) {
  const {
    value: session
  } = useLightweightTransaction<SigningSession>({
    parentPath: formPath,
    myPath: 'signing.session',
    bindToMetaKey: true
  });
  const {
    value: signingParty
  } = useLightweightTransaction<SigningParty>({
    parentPath: formPath,
    myPath: `signing.parties.[${partyId}]`,
    bindToMetaKey: true
  });
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  if (!(session?.initiator && signingParty && sessionInfo)) {
    return <></>;
  }

  const initiatorName = session.initiator.name;
  const localEntity = useEntity(session.initiator.entity.id);
  const entityName = companyTradingWithFallback(localEntity?.name, localEntity?.tradeName);
  const proxyMode = Predicate.proxyNotSelf(signingParty.proxyAuthority);
  const partyName = (proxyMode ? signingParty.proxyName : signingParty.snapshot?.name) || 'Unknown';

  return <Modal.Dialog className={'bg-white mt-5 p-3'} style={{ maxWidth: '600px' }}>
    <Modal.Header className={'mb-3'}>
      <Avatar name={initiatorName} entityName={entityName} agentId={session.initiator.id} />
    </Modal.Header>
    <Modal.Body>
      <p>{sessionInfo.name}, please pass control of this device to <b>{partyName}</b>.</p>
    </Modal.Body>
    <Modal.Footer>
      <div className={'d-flex flex-column'}>
        {/*<div>Check here to skip this message in the future.</div>*/}
        <div className={'d-flex flex-row gap-3 justify-content-end'}>
          <Button variant={'outline-secondary'} onClick={() => cancel()}>Cancel</Button>
          <Button variant={'light'} onClick={() => next()}>Continue</Button>
        </div>
      </div>
    </Modal.Footer>
  </Modal.Dialog>;
}

export function TermsAndConditionsModal ({ showAgreement, closeAgreement, noDeclineStatement }: {showAgreement: boolean, closeAgreement: () => void, noDeclineStatement?: boolean}) {
  return <Modal show={showAgreement} onHide={closeAgreement} dialogClassName={'tandc-modal'} >
    <Modal.Header closeButton>
      reaforms Terms and Conditions Electronic Communications and Signing
    </Modal.Header>

    <Modal.Body className='terms-and-conditions-body'>
      <MarkdownRenderer url='/legacy/help/md/signing_terms_consent.md' />
    </Modal.Body>

    <Modal.Footer>
      <Button variant={'outline-secondary'} onClick={closeAgreement}>Close</Button>
    </Modal.Footer>
  </Modal>;
}

function SigningPartySigning(props: { next: () => void }) {
  return <></>;
}

function SigningPartyPassControlToSalesperson({ next }: { next: () => void }) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  if (!sessionInfo) {
    return <></>;
  }

  return <Modal.Dialog className={'bg-white mt-5 p-3'} style={{ maxWidth: '600px' }}>
    <Modal.Header className={'mb-3'}>
      <h5>Return control of device</h5>
    </Modal.Header>
    <Modal.Body>
      Please return control of the device back to <b>{sessionInfo.name}</b>
    </Modal.Body>
    <Modal.Footer>
      <div className={'d-flex flex-column'}>
        {/*<div>Check here to skip this message in the future.</div>*/}
        <div className={'d-flex flex-row gap-3 justify-content-end'}>
          <Button variant={'light'} onClick={() => next()}>Continue</Button>
        </div>
      </div>
    </Modal.Footer>
  </Modal.Dialog>;
}

function NotifySalespersonOfPartyDecline({ next, formPath, partyId }: { next: () => void, formPath: string, partyId: string }) {
  const {
    value: signingParty
  } = useLightweightTransaction<SigningParty>({
    parentPath: formPath,
    myPath: `signing.parties.[${partyId}]`,
    bindToMetaKey: true
  });

  const proxyMode = Predicate.proxyNotSelf(signingParty?.proxyAuthority);
  const partyName = (proxyMode ? signingParty?.proxyName : signingParty?.snapshot?.name) || 'Unknown';

  const declineText = signingParty?.declineType === SigningPartyDeclineType.TermsAndConditions
    ? 'declined the Terms and Conditions'
    : 'declined to sign';

  return <Modal.Dialog className={'bg-white mt-5 p-3'} style={{ maxWidth: '600px' }}>
    <Modal.Header className={'mb-3'}>
      <h5>Party Declined</h5>
    </Modal.Header>
    <Modal.Body>
      <p>{partyName} has {declineText} with the following reason:</p>
      <p>{signingParty?.declineReason}</p>
    </Modal.Body>
    <Modal.Footer>
      <div className={'d-flex flex-column'}>
        {/*<div>Check here to skip this message in the future.</div>*/}
        <div className={'d-flex flex-row gap-3 justify-content-end'}>
          <Button variant={'light'} onClick={() => next()}>Continue</Button>
        </div>
      </div>
    </Modal.Footer>
  </Modal.Dialog>;
}

const signingProcessVisibleStates = new Set([
  HostedSigningInstructionState.SigningPartySigning
]);

export function NonSalespersonHostedSigningInstructionWrapper({
  formPath,
  propertyId,
  formId,
  formCode,
  partyId,
  onExit,
  onFailure
}: {
  formPath: string,
  propertyId: string,
  formId: string,
  formCode: string,
  partyId: string,
  onExit: () => void,
  onFailure: () => void
}) {
  const [state, setState] = useState(HostedSigningInstructionState.SalespersonProcessOverview);
  const [partyDeclined, setPartyDeclined] = useState(false);
  const [failed, setFailed] = useState(false);
  const nextState = () => {
    if (state === HostedSigningInstructionState.SigningPartyPassControlToSalesperson) {
      // we're at the end;
      return;
    }
    // since they're an uncomplicated ordered list of steps, we can just increment this.
    // if we start going all funky then implement a proper transition function
    setState(state + 1);
  };
  // for debug
  const partyDecline = () => {
    setPartyDeclined(true);
    setState(HostedSigningInstructionState.SigningPartyPassControlToSalesperson);
  };

  return <div className={'hosted-signing w-100 h-100'}>
    {(() => {
      const signingProcessNode = signingProcessVisibleStates.has(state)
        ? <SigningProcessShim
          partyId={partyId}
          formPath={formPath}
          formId={formId}
          formCode={formCode}
          propertyId={propertyId}
          hosted={{
            onDeclineToSign: () => partyDecline(),
            onCancel: () => onExit(),
            formPath
          }}
          onSigningComplete={() => nextState()}
          onFailure={() => {
            setFailed(true);
            setState(HostedSigningInstructionState.SigningPartyPassControlToSalesperson);
          }} />
        : <></>;
      switch (state) {
        case HostedSigningInstructionState.SalespersonProcessOverview:
          return <SalespersonProcessOverview
            next={nextState}
            cancel={() => onExit()}
            formPath={formPath}
            partyId={partyId}
            documentName={FormTypes[formCode].label}/>;
        case HostedSigningInstructionState.SalespersonVerifyIdentityOfSigningParty:
          return <SalespersonVerifyIdentityOfSigningParty
            next={nextState}
            cancel={() => onExit()}
            formPath={formPath}
            partyId={partyId} />;
        case HostedSigningInstructionState.SalespersonPassControlToSigningParty:
          return <SalespersonPassControlToSigningParty
            next={nextState}
            cancel={() => onExit()}
            formPath={formPath}
            partyId={partyId} />;
        case HostedSigningInstructionState.SigningPartySigning:
          return <>
            <SigningPartySigning
              next={nextState} />
            {signingProcessNode}
          </>;
        case HostedSigningInstructionState.SigningPartyPassControlToSalesperson:
          return <SigningPartyPassControlToSalesperson
            next={() => {
              if (partyDeclined) {
                setState(HostedSigningInstructionState.NotifySalespersonOfPartyDecline);
                return;
              }
              if (failed) {
                onFailure();
                return;
              }
              onExit();
            }} />;
        case HostedSigningInstructionState.NotifySalespersonOfPartyDecline:
          return <NotifySalespersonOfPartyDecline
            next={onExit}
            formPath={formPath}
            partyId={partyId}/>;
      }
    })()}
  </div>;
}
